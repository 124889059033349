<template>
  <div class="players_container">
    <Player :player="teamTotals" :side="''" />
    <Player
      v-for="(player, index) in realPlayers"
      :key="'player-' + index"
      :player="player"
      :side="''"
    />
    <!--div>{{ teamTotals }}</!--div-->
  </div>
</template>

<script>
import axios from "axios";
import Player from "@/components/field/players/player/Player.vue";
export default {
  data() {
    return {};
  },
  props: {
    team: {
      type: Array,
      required: true,
    },
  },
  components: {
    Player,
  },
  computed: {
    tableMode() {
      return this.$store.state.table.tableMode;
    },
    realPlayers() {
      return this.team.filter((e) => {
        if (e.jerseyNum != "T") {
          return e;
        }
      });
    },
    teamTotals() {
      // Se sono in modalità Cumulative estraggo la riga da players altrimenti calcolo

      function sumArrays(array) {
        let result = [];
        if (array.length > 0) {
          const width = array[0].length;
          result = Array.from({ length: width });
          for (var col = 0; col < width; col++) {
            const mapCol = array.map((x) => x[col]);
            result[col] = array
              .map((x) => x[col])
              .reduce((sum, x) => sum + x, 0);
          }
        }
        return result;
      }

      let retVal = {};

      if (this.team != undefined && this.team.length > 0) {
        if (this.tableMode == "Cumulative") {
          const totParams = this.team.filter((e) => {
            if (e.jerseyNum == "T") {
              return e;
            }
          })[0].parameters;

          retVal = {
            color: "_blue",
            jerseyNum: "",
            name: "TEAM AVERAGE",
            alarmsValues: [],
            parameters: totParams,
            cntCircle: null,
          };
        } else {
          // function sumArrays(...arrays) {
          //   const n = arrays.reduce((max, xs) => Math.max(max, xs.length), 0);
          //   const result = Array.from({ length: n });
          //   return result.map((_, i) => arrays.map(xs => xs[i] || 0).reduce((sum, x) => sum + x, 0));
          // }

          let len = this.team.length;
          let alarmsValuesMap = [];
          let alarmsValuesSum = 0;
          let alarmsValuesAvg = 0;
          if (len > 0 && this.team[0].alarmsValues != undefined) {
            alarmsValuesMap = this.team.map((x) => [
              parseInt(x.alarmsValues[0].toFixed(0)),
              parseInt(x.alarmsValues[1].toFixed(0)),
            ]);
            alarmsValuesSum = sumArrays(alarmsValuesMap);
            alarmsValuesAvg = alarmsValuesSum.map((x) => x / len);
          }
          let parametersMap = this.team.map((x) => x.parameters);
          let parametersValueArr = [];
          parametersMap.forEach((element) =>
            parametersValueArr.push(element.map((x) => parseFloat(x.value)))
          );
          //let parametersValueArr = parametersMap.map((x) => x.value);
          let parametersSum = sumArrays(parametersValueArr);
          let parametersAvg = parametersSum.map((x) =>
            Math.floor(x / len).toFixed(0)
          );
          let parametersAvgObj = [];
          parametersAvg.forEach((element) =>
            parametersAvgObj.push({
              value: element,
              up: false,
              down: false,
            })
          );
          let cntCirleSum = 0;
          if (this.team[0].alarmsValues != undefined)
            cntCirleSum = this.team
              .map((x) => parseInt(x.cntCircle))
              .reduce((a, b) => a + b, 0);
          //      let alarmsValuesAvg = alarmsValuesSum.map( x => x/len);
          //      let alarmsValuesAvg = alarmsValuesSum.map( x => x/len);

          retVal = {
            color: "_blue",
            jerseyNum: "",
            name: "TEAM AVERAGE",
            alarmsValues: alarmsValuesAvg,
            parameters: parametersAvgObj,
            cntCircle: cntCirleSum,
          };
        }
      }

      return retVal;
    },
  },
  methods: {},
};
</script>

<style lang="scss">
.players_container {
  margin-bottom: 30px;
}
.header_data {
  display: flex;
  flex-direction: row;
  font-weight: bold;
  font-size: 12px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: var(--table-border-bottom);
  width: 95%;
  &\:name {
    width: 300px;
    margin-left: 15px;
    text-align: center;
  }
  &\:container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 62vw;
    margin-left: 20px;
  }
  &\:val {
    width: 10vw;
    text-align: center;
  }
}
</style>
