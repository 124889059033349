<template>
  <v-app>
    <div>
      <Header />
      <SetTableMode />
      <div id="tablepage">
        <div class="header_data">
          <div class="header_data:name">Name Player</div>
          <div class="header_data:container">
            <div
              class="header_data:val"
              v-for="(val, index) in parameters"
              :key="index"
            >
              {{ val }}
            </div>
          </div>
        </div>
        <div id="player_table">
          <Table :team="teamHome" v-if="homeButton" key="refreshHCnt" />
          <Table :team="teamAway" v-if="awayButton" key="refreshACnt" />
        </div>
        <Card :player="player" />
      </div>
      <Connection />
      <Menu />
    </div>
  </v-app>
</template>

<script>
import axios from "axios";
import * as XLSX from "xlsx";
import Table from "@/components/table/Table.vue";
import Card from "@/components/general/card/Card.vue";
import Header from "@/components/general/header/Header";
import Connection from "@/components/connection/Connection.vue";
import Menu from "@/components/general/nav/menu.vue";
import SetTableMode from "@/components/table/SetTableMode";
export default {
  data() {
    return {
      player: {},
      parameters: [],
      refreshTimer: "",
      refreshHCnt: 0,
      refreshACnt: 0,
    };
  },
  components: {
    Table,
    Card,
    Header,
    Connection,
    Menu,
    SetTableMode,
  },
  watch: {
    card(val) {
      this.players.filter((e) => {
        if (
          e.jerseyNum == val.jerseyNum &&
          e.isOppositeTeam == val.isOppositeTeam
        ) {
          return (this.player = e);
        }
      });
    },
    exportCsvButton(val) {
      if (val) {
        this.csvExport(this.parameters, this.players);
      }
      this.$store.commit("exportcsv", false);
    },
    tableMode(val) {
      this.initTable();
    },
  },
  computed: {
    players() {
      return this.$store.state.players.players;
    },
    awayButton() {
      return this.$store.state.table.away;
    },
    homeButton() {
      return this.$store.state.table.home;
    },
    filters() {
      return this.$store.state.field.filters;
    },
    card() {
      return this.$store.state.field.card.data;
    },
    teamHome() {
      return this.players.filter((e) => {
        if (e.isOppositeTeam == false) {
          return e;
        }
      });
    },
    teamAway() {
      return this.players.filter((e) => {
        if (e.isOppositeTeam == true) {
          return e;
        }
      });
    },
    userid() {
      return this.$store.state.user.user.id;
    },
    exportCsvButton() {
      return this.$store.state.table.exportcsv;
    },
    idgame() {
      return this.$store.state.idgame;
    },
    postactive() {
      return this.$store.state.post.active;
    },
    offline() {
      return this.$store.state.offline;
    },
    headerConfig() {
      return this.$store.getters.getHeaderConfig;
    },
    sessionid() {
      return this.$store.state.post.sessionid;
    },
    postid() {
      return this.$store.state.post.postid;
    },
    storeidgame() {
      return this.$store.state.storeIdgame;
    },
    tableMode() {
      return this.$store.state.table.tableMode;
    },
  },
  methods: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    fetchData() {
      axios.get("/static/json/DataTemplate.json").then((response) => {
        this.players = response.data;
      });
    },
    fetchDataParam() {
      // La URL da richiamare cambia in funzione di live e post e del tableMode
      let url = process.env.VUE_APP_API_URL + "/kpilive/" + this.idgame;
      if (this.postactive) {
        url = process.env.VUE_APP_API_URL + "/kpipost/" + this.postid;
      } else if (this.offline || this.tableMode == "Cumulative") {
        url =
          process.env.VUE_APP_API_URL +
          "/kpiliveoffline/" +
          (this.offline ? this.storeidgame : this.idgame);
      }

      axios
        .get(url, this.headerConfig)
        .then((response) => {
          if (response.data) {
            let data = response.data
              .filter((x) => x.isAlarm == "0")
              .map((e) => {
                return e.short_desc;
              });
            this.parameters = data;
          }
        })
        .catch((err) => {
          if (!this.postactive) {
            axios
              .get(process.env.VUE_APP_API_URL + "/kpi", this.headerConfig)
              .then((response) => {
                if (response.data) {
                  let data = response.data
                    .filter((x) => x.isAlarm == "0")
                    .map((e) => {
                      return e.short_desc;
                    });
                  this.parameters = data;
                }
              });
          }
        });
    },
    csvExport(parameters, playersData) {
      /*
      let csvContent = "data:text/csv;charset=utf-8,";
      let extendedParms = [...parameters];
      extendedParms.unshift("Team", "Jersey Number", "Player Name");
      csvContent += [
        extendedParms.join(";"),
        ...playersData.map(
          (player) =>
            (player.isOppositeTeam ? "Away" : "Home") +
            ";" +
            player.jerseyNum +
            ";" +
            player.name +
            ";" +
            [...player.parameters].map((parameter) => parameter.value).join(";")
        ),
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

             //      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", xlsx);
      link.setAttribute("download", "smart_gear_export.csv");
      link.click();
      */

      //e.preventDefault();
      //var wb = XLSX.read("1,2,3,4", { type: "string" });
      const fileName = "smart_live_export.xlsx";
      let extendedParms = [...parameters];
      let data4Excel = [];
      extendedParms.unshift("Team", "Jersey Number", "Player Name");
      for (const player of playersData.sort(function(a, b) {
        //Ordino per Team + Numero
        if (a.isOppositeTeam == b.isOppositeTeam) {
          if (a.jerseyNum == b.jerseyNum) return -0;
          if (a.jerseyNum < b.jerseyNum) return -1;
          if (a.jerseyNum > b.jerseyNum) return 1;
        }
        if (a.isOppositeTeam < b.isOppositeTeam) return -1;
        if (a.isOppositeTeam > b.isOppositeTeam) return 1;
      })) {
        let row = {};
        for (let col = 0; col < extendedParms.length; col++) {
          switch (col) {
            case 0:
              row["Team"] = player.isOppositeTeam ? "Away" : "Home";
              break;

            case 1:
              row["Jersey Number"] = player.jerseyNum;
              break;

            case 2:
              row["Player Name"] = player.name;
              break;

            default:
              row[extendedParms[col]] = player.parameters[col - 3].value;
              break;
          }
        }
        data4Excel.push(row);
      }

      const ws = XLSX.utils.json_to_sheet(data4Excel);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "export");
      XLSX.writeFile(wb, fileName);
    },
    initTable() {
      // Caso Live e Post
      if (this.tableMode != "Historical") {
        this.fetchDataParam();
        clearInterval(this.refreshTimer);
        // Verifico il liveId per capire se sono in live o in post
        const isLive = this.$store.state.idgame && this.$store.state.idgame > 0;
        // SOLO SE SONO IN LIVE
        if (isLive) {
          // Nel caso dei cumulativi estrae i dati ed avvia il timer altrimenti blocca il timer
          if (this.tableMode == "Cumulative") {
            this.$store.commit("players", []);
            this.readCumulativeDataFromAPI();
            this.refreshTimer = setInterval(
              this.readCumulativeDataFromAPI,
              30000
            );
          } else if (!this.offline) {
            /*
             * Opzione Realtime:
             *   Se sono in live e offline è spento vuol dire che i dati arrivano dal websocket quindi pulisco l'array che verrà popolato successivamente dal websocket
             *   altrimenti i dati sono già popolati dall'API e non devo cancellarli
             */
            this.$store.commit("players", []);
          }
        }
        this.refreshHCnt++;
        this.refreshACnt++;
      } else {
        // Historical
        // Aggancio i dati dal DB
        const historyId = this.$store.state.history.historyid;
        const apiUrl = process.env.VUE_APP_API_URL + "/history/" + historyId;
        axios
          .get(apiUrl)
          .then((response) => {
            // La struttura della response contiene sia le intestazioni che i valori
            // Dobbiamo rimappare nel formato atteso

            // Intestrazione: parameters vuole una lista di short_desc. Le trovo nella sezione header in 12 colonne
            this.parameters = [];
            const header = response.data.header;
            if (header.kpi_descr_01 != "")
              this.parameters.push(header.kpi_descr_01);
            if (header.kpi_descr_02 != "")
              this.parameters.push(header.kpi_descr_02);
            if (header.kpi_descr_03 != "")
              this.parameters.push(header.kpi_descr_03);
            if (header.kpi_descr_04 != "")
              this.parameters.push(header.kpi_descr_04);
            if (header.kpi_descr_05 != "")
              this.parameters.push(header.kpi_descr_05);
            if (header.kpi_descr_06 != "")
              this.parameters.push(header.kpi_descr_06);
            if (header.kpi_descr_07 != "")
              this.parameters.push(header.kpi_descr_07);
            if (header.kpi_descr_08 != "")
              this.parameters.push(header.kpi_descr_08);
            if (header.kpi_descr_09 != "")
              this.parameters.push(header.kpi_descr_09);
            if (header.kpi_descr_10 != "")
              this.parameters.push(header.kpi_descr_10);
            if (header.kpi_descr_11 != "")
              this.parameters.push(header.kpi_descr_11);
            if (header.kpi_descr_12 != "")
              this.parameters.push(header.kpi_descr_12);
            const numParameters = this.parameters.length;

            // Formatto i dati per players. Metto tutte le properties anche se ho solo il valore
            let data = [];
            const rows = response.data.rows;
            let parBase = {
              value: 0,
              up: false,
              down: false,
              name: "",
              type: "",
              valueCalc: 0,
              decimals: 0,
            };
            for (let row of rows) {
              let singleRowParameters = [];
              if (numParameters >= 1) {
                const par = { ...parBase }
                par.value = row.kpi_value_01;
                par.name = this.parameters[0];
                singleRowParameters.push(par);
              }
              if (numParameters >= 2) {
                const par = { ...parBase }
                par.value = row.kpi_value_02;
                par.name = this.parameters[1];
                singleRowParameters.push(par);
              }
              if (numParameters >= 3) {
                const par = { ...parBase }
                par.value = row.kpi_value_03;
                par.name = this.parameters[2];
                singleRowParameters.push(par);
              }
              if (numParameters >= 4) {
                const par = { ...parBase }
                par.value = row.kpi_value_04;
                par.name = this.parameters[3];
                singleRowParameters.push(par);
              }
              if (numParameters >= 5) {
                const par = { ...parBase }
                par.value = row.kpi_value_05;
                par.name = this.parameters[4];
                singleRowParameters.push(par);
              }
              if (numParameters >= 6) {
                const par = { ...parBase }
                par.value = row.kpi_value_06;
                par.name = this.parameters[5];
                singleRowParameters.push(par);
              }
              if (numParameters >= 7) {
                const par = { ...parBase }
                par.value = row.kpi_value_07;
                par.name = this.parameters[6];
                singleRowParameters.push(par);
              }
              if (numParameters >= 8) {
                const par = { ...parBase }
                par.value = row.kpi_value_08;
                par.name = this.parameters[7];
                singleRowParameters.push(par);
              }
              if (numParameters >= 9) {
                const par = { ...parBase }
                par.value = row.kpi_value_09;
                par.name = this.parameters[8];
                singleRowParameters.push(par);
              }
              if (numParameters >= 10) {
                const par = { ...parBase }
                par.value = row.kpi_value_10;
                par.name = this.parameters[9];
                singleRowParameters.push(par);
              }
              if (numParameters >= 11) {
                const par = { ...parBase }
                par.value = row.kpi_value_11;
                par.name = this.parameters[10];
                singleRowParameters.push(par);
              }
              if (numParameters >= 12) {
                const par = { ...parBase }
                par.value = row.kpi_value_12;
                par.name = this.parameters[11];
                singleRowParameters.push(par);
              }

              let singleData = {
                id: row.homeaway + row.jersey_num,
                isOppositeTeam: row.homeaway == "H" ? false : true,
                jerseyNum: row.jersey_num,
                name: row.player_name,
                parameters: singleRowParameters,
              };
              data.push(singleData);
            }
            this.$store.commit("players", data);
          })
          .catch((error) => {
            console.log(
              "Error on reading historical data for id  " +
                historyId +
                " ==> " +
                error
            );
          });
      }
    },
    async readCumulativeDataFromAPI() {
      const data = {
        liveid: this.$store.state.idgame,
        starttime: 0,
        endtime: 999999999999,
        userid: this.userid,
      };
      const apiUrl = process.env.VUE_APP_POSTS_SERVER + "/livecalc/interval";
      axios
        .post(apiUrl, data)
        .then((response) => {
          //var calculationsString = JSON.parse(response.data);
          //this.$store.commit("activeredirect", true);
          this.$store.commit("players", response.data);
        })
        .catch((error) => {
          console.log("Error on readCumulativeDataFromAPI " + error);
        });
    },
  },
  created() {
    if (!this.loggedIn()) {
      this.$router.push("/login");
    }
    this.initTable();
  },
  destroyed() {
    clearInterval(this.refreshTimer);
  },
};
</script>

<style lang="scss">
#tablepage {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 75vh;
}
#player_table {
  width: 95%;
  height: 59vw;
  overflow: hidden;
  overflow-y: scroll;
}
</style>
