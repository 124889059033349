var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "players_container" },
    [
      _c("Player", { attrs: { player: _vm.teamTotals, side: "" } }),
      _vm._l(_vm.realPlayers, function(player, index) {
        return _c("Player", {
          key: "player-" + index,
          attrs: { player: player, side: "" }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }