var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-app", [
    _c(
      "div",
      [
        _c("Header"),
        _c("SetTableMode"),
        _c(
          "div",
          { attrs: { id: "tablepage" } },
          [
            _c("div", { staticClass: "header_data" }, [
              _c("div", { staticClass: "header_data:name" }, [
                _vm._v("Name Player")
              ]),
              _c(
                "div",
                { staticClass: "header_data:container" },
                _vm._l(_vm.parameters, function(val, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "header_data:val" },
                    [_vm._v(" " + _vm._s(val) + " ")]
                  )
                }),
                0
              )
            ]),
            _c(
              "div",
              { attrs: { id: "player_table" } },
              [
                _vm.homeButton
                  ? _c("Table", {
                      key: "refreshHCnt",
                      attrs: { team: _vm.teamHome }
                    })
                  : _vm._e(),
                _vm.awayButton
                  ? _c("Table", {
                      key: "refreshACnt",
                      attrs: { team: _vm.teamAway }
                    })
                  : _vm._e()
              ],
              1
            ),
            _c("Card", { attrs: { player: _vm.player } })
          ],
          1
        ),
        _c("Connection"),
        _c("Menu")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }